import React from 'react';
import { graphql} from 'gatsby';
import ContactUs from '../../templates/multilanguage/ContactUs';


export default ({ data }) => {
  return <ContactUs data={data} />;
};

export const query = graphql`
 query ContactUsRo {
  mdx(frontmatter: {type: {eq: "contact-us"} language: {eq: "pl"}}) {
      id
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          fr {
            url
            country
          }
          es {
            url
            country
          }
          pl {
            url
            country
          }
          de {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        metaKeywords
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        twitterCard
        ldJson
        headerText
        contactHeader
        contactDescription
        namePlaceholder
        nameIcon
        emailPlaceholder
        emailIcon
        phoneNumberPlaceholder
        phoneNumberIcon
        messagePlaceholder
        messageIcon
        submitButtonText
        fileUploadText
        chooseFileButtonText
        noFileChosenText
        phoneNumberHeader
        phoneNumbers
        phoneCountries
        emailHeader
        emails
        emailTexts
        headquarterHeader
        headquarterLines
      }
  }
}
`
